import React from "react";

export default function section4() {
  return (
    <div>
      <section
        class="py-5 md:py-24 lg:py-28 sm:px-24 bg-white overflow-hidden"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap lg:items-center -m-8">
            <div class="w-full md:w-1/2 p-8">
              <div class="max-w-xl">
                <h2 class="font-heading mb-6 text-4xl md:text-5xl  font-semibold text-amber-950">
                33<sup>rd</sup> edition luxurious collections
                </h2>
                <p class="mb-8 text-2xl">
                  The practical platform for display & sale of spectacular Gold,
                  Diamond, Platinum, Gems, Pearls & Silver Jewellery.
                </p>
                <a
                  class="inline-block px-5 py-2 text-white font-semibold  bg-amber-950  rounded-lg focus:ring-4 focus:ring-amber-900 transition duration-200"
                  href="/contact"
                >
                  Know More
                </a>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-4 md:p-8">
              <div class="max-w-md mx-auto md:mr-0">
                <div class="flex flex-wrap md:-m-10 text-red-950">
                  <div class="w-full lg:w-1/2 p-2 md:p-10">
                    <h3 class="font-heading mb-2 inline-block text-4xl md:text-5xl ">
                      100+
                    </h3>
                    <p class="text-2xl font-bold">Associated Jewellers</p>
                  </div>
                  <div class="w-full lg:w-1/2 p-2 md:p-10">
                    <h3 class="font-heading mb-2 inline-block text-4xl md:text-5xl ">
                      25+
                    </h3>
                    <p class="text-2xl font-bold">Shows</p>
                  </div>
                  <div class="w-full lg:w-1/2 p-2 md:p-10">
                    <h3 class="font-heading mb-2 inline-block text-4xl md:text-5xl ">
                      100000+
                    </h3>
                    <p class="text-2xl font-bold">Visitors</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
